import {UUID} from "../util/UUID";
import {IShape} from "../../store/drawing/state";

export interface GroupData {
    shape : IShape,
    id : string,
    sceneId : string
}

export class GroupEntity {
    
    
    readonly id: UUID;
    private shapes = new Array<IShape>();
    private _order : number = 1;
    readonly sceneId: UUID;
    
    constructor(id : UUID, sceneId:UUID) {
        this.id = id;
        this.sceneId = sceneId;
    }

    get order(): number {
        return this._order;
    }

    set order(value: number) {
        this._order = value;
    }
    
    public addShape(shape : IShape) {
        this.shapes.push(shape);
    }    
    
    public getShapes() : IShape[]{
        return this.shapes;
    }
    
    public static createNewWithShape(sceneId:UUID, shape : IShape) : GroupEntity{
        const groupEntity = new GroupEntity(UUID.newUUID(), sceneId);
        groupEntity.addShape(shape);
        return groupEntity
    }
}