import {ActionType} from "../index";
import {SystemState} from "./state";


export enum SystemActionsType{
    Save
}


const initialState:SystemState = { 
    lastSaved:""
};


export function systemReducer(state = initialState, action: ActionType<SystemActionsType>): SystemState {
    return {...state, lastSaved:new Date().getUTCMilliseconds().toString()};
}

