export interface IList<T>{
   
}

export class List<T> implements IList<T>{

    private arr : Array<T>;
    
    constructor(arr : Array<T> = new Array<T>()) {
        this.arr = arr;
    }
    
    public addAll(arr: T[]) {
        arr.forEach(value => this.arr.push(value));
    }

    findIndexOf(match: (elm : T) => boolean) : number | null{
        for (let i=0; i<this.arr.length; i++){
            const val = this.arr[i];
            if(match(val))
                return i;
        }
        return null;
    }

    removeIndex(index: number) {
        const length = this.arr.length;
        if(index<0 || index >= length)
            throw Error("Invalid index");
        for (let i=0; i<length; i++){
            const val = this.arr.shift();
            if(i !== index && val)
                this.arr.push(val);
        }
    }

    insert(index: number, item: T) {
        const length = this.arr.length;
        if(index<0 || index > length)
            throw Error("Invalid index");
        
        if(index === 0){
            this.arr.unshift(item);
            return;
        }
            
        for (let i=length-1; i>=0; i--){
            if(i === length-1 && index === length)
                this.arr.unshift(item);
            const val = this.arr.pop();
            if(val)
                this.arr.unshift(val);
            if(i === index)
                this.arr.unshift(item);
        }
    }

    add(item: T) {
        this.insert(this.arr.length, item);
    }

    replace(item: T, comparator: (a1 : T, a2 : T) => boolean) : boolean {
        const index = this.findIndexOf(elm => comparator(item,elm));
        if(index !== null) {
            this.removeIndex(index);
            this.insert(index,item);
            return true;
        }
        return false;
    }
}