import "./PreviewMenu.css"
import React, {PropsWithChildren, RefObject} from 'react';

interface Props {
    selected : boolean
    handleShowEvent : () => void;
    handleDeleteEvent : () => void;
    handleSelectEvent : () => void;
}


export class PreviewMenu extends React.Component<PropsWithChildren<Props>>{

    private divRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    
    constructor(props: Readonly<Props>) {
        super(props);
        this.showMenu.bind(this);
    }

    render(){
        const previewClassName = "preview-menu list-group-item list-group-item-action"+(this.props.selected? " selected" : "");
        return (
            <div className={previewClassName} onClick={this.props.handleSelectEvent} onMouseOver={()=>this.showMenu(true)} onMouseOut={()=>this.showMenu(false)}>
                
                <div className="overlay-menu" ref={this.divRef}>
                    <div className="icon" onClick= {this.props.handleShowEvent}>
                        <svg className="bi bi-collection-play" viewBox="0 0 16 16"
                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M14.5 13.5h-13A.5.5 0 011 13V6a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v7a.5.5 0 01-.5.5zm-13 1A1.5 1.5 0 010 13V6a1.5 1.5 0 011.5-1.5h13A1.5 1.5 0 0116 6v7a1.5 1.5 0 01-1.5 1.5h-13zM2 3a.5.5 0 00.5.5h11a.5.5 0 000-1h-11A.5.5 0 002 3zm2-2a.5.5 0 00.5.5h7a.5.5 0 000-1h-7A.5.5 0 004 1z"
                                  clipRule="evenodd"/>
                            <path fillRule="evenodd"
                                  d="M6.258 6.563a.5.5 0 01.507.013l4 2.5a.5.5 0 010 .848l-4 2.5A.5.5 0 016 12V7a.5.5 0 01.258-.437z"
                                  clipRule="evenodd"/>
                        </svg>                        
                    </div>
                    <div className="icon" onClick= {this.props.handleDeleteEvent}>
                        <svg className="bi bi-trash" viewBox="0 0 16 16" fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.5 5.5A.5.5 0 016 6v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm3 .5a.5.5 0 00-1 0v6a.5.5 0 001 0V6z"/>
                            <path fillRule="evenodd"
                                  d="M14.5 3a1 1 0 01-1 1H13v9a2 2 0 01-2 2H5a2 2 0 01-2-2V4h-.5a1 1 0 01-1-1V2a1 1 0 011-1H6a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zM4.118 4L4 4.059V13a1 1 0 001 1h6a1 1 0 001-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                  clipRule="evenodd"/>
                        </svg>                        
                    </div>
                </div>
                
                {this.props.children}
            </div>
        );
    }
    
    componentDidMount(): void {
        if(this.props.selected)
            this.showMenu(true);
    }

    private showMenu(show:boolean){
        if(!this.divRef.current)
            return;

        const name = "active";
        if(show)
            this.divRef.current.classList.add(name);
        else
            this.divRef.current.classList.remove(name);
    }
}
