import {GroupData} from "./groupEntity";
import {UUID} from "../util/UUID";
import {SceneEntity} from "./sceneEntity";

export class SceneEntityList {
    private scenes: SceneEntity[];

    constructor(groups: GroupData[] = []) {
        const set = new Set<string>();
        groups.forEach(value => set.add(value.sceneId));
        const scenes = new Array<UUID>();
        set.forEach(uuid => scenes.push(new UUID(uuid)));
        this.scenes = scenes.sort((a, b) => a.toString().localeCompare(b.toString()))
                            .map(value => new SceneEntity(value));
        if(scenes.length <= 0)
            this.resetToOne();
    }

    private resetToOne() {
        this.scenes = new Array<SceneEntity>(new SceneEntity(UUID.newUUID()));
    }

    GetFirst() : SceneEntity {
        return this.scenes[0];
    }

    getAll() : SceneEntity[]{
        return this.scenes.slice(0);
    }

    add(newScene: SceneEntity) {
        this.scenes.push(newScene);
        console.log(this.scenes);
    }

    removeAll() {
        this.resetToOne();
    }
}