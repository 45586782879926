import * as React from "react";
import {ModalDialog} from "../thirdparty_presenters/ModalDialog";
import {UUID} from "../core/util/UUID";
import {IShape} from "../store/drawing/state";
import CpuUsage = NodeJS.CpuUsage;

export interface ActionViewModel {
    sceneId: UUID;
}

export interface EventViewModel {
}

export interface TriggerViewModel {
    actionId : UUID,
    name : string,
    event : EventViewModel | null
    action : ActionViewModel | null
}

export  interface GroupViewModel {
    id : UUID
}

export interface EditActionDialogProps {
    item : TriggerViewModel | null
    groups : GroupViewModel[]
}

export interface EditActionDialogEvents {
    onOK : (item : TriggerViewModel) => void;
}

interface State{
}

export class EditActionDialog extends React.Component<EditActionDialogProps & EditActionDialogEvents, State> {
    
    constructor(props : EditActionDialogProps & EditActionDialogEvents) {
        super(props);
    }
    
    render(){
        if(this.props.item === null)
            return <></>;
        const item = this.props.item;
        
        return (
            <ModalDialog show={true} 
                         title={"Add action"} 
                         onOK={() => {
                            this.props.onOK(item)
                         }}>
                <div>
                    Name: 
                    <input type="text" 
                           defaultValue={item.name} 
                           onChange={event => item.name = event.target.value}/>
                </div>
                <div>
                    {this.renderEvent(item.event)}
                </div>
                <div>
                    {this.renderAction(item)}
                </div>
            </ModalDialog>
        );
    }

    private renderEvent(event: EventViewModel | null) {
        return <>
            Select trigger event:
            <div>
                click
            </div>
        </>;
    }

    private renderAction(item : TriggerViewModel) {
        const options = this.props.groups.map((value,index) => {
                const shapeId = value.id.toString();
                return (<option key={index} value={shapeId}>{shapeId}</option>)
            }
        );
        
        if(!item.action)
            item.action = {sceneId: this.props.groups[0].id};
        const selected = item.action.sceneId.toString();
        
        return <>
            Select goto scene
            <div>
                Select target shape:
                <select className="form-control" 
                        defaultValue={selected} 
                        onChange={event => item.action = {sceneId : new UUID(event.target.value)}}>
                    {options}
                </select>
            </div>
        </>;
    }
}
