import {Action, bindActionCreators, Dispatch} from "redux";
import {Component, ComponentType, JSXElementConstructor} from "react";
import {ConnectPropsMaybeWithoutContext, ConnectedComponent, DistributiveOmit, GetLibraryManagedProps, GetProps, InferableComponentEnhancer, InferableComponentEnhancerWithProps, Mapped, Matching, Shared, connect} from "react-redux";
import {ActionType} from "../store";

export abstract class ReduxMapper<TState, TPropsData, TPropsEvents, TOwnProps> {

    protected abstract mapState(state: TState, props: TOwnProps): TPropsData

    protected abstract dispatchEvents(dispatch: Dispatch<Action<any>>, props: TOwnProps): TPropsEvents

    public connect<C extends ComponentType<Matching<TPropsData & TPropsEvents, GetProps<C>>>>(component: C){
         return connect(this.mapState, this.dispatchEvents)(component);
    }
}

export abstract class ReduxMapperSimple<TState, TPropsData, TPropsEvents> {

    protected abstract mapState(state: TState): TPropsData

    protected abstract dispatchEvents(dispatch : (func : ()=>ActionType<any>) => void): TPropsEvents
    
    public connect<C extends ComponentType<Matching<TPropsData & TPropsEvents, GetProps<C>>>>(component: C){
        const dispatchWrapper = (dispatch : Dispatch<Action<any>>)=>{
            const wrapper : (func : ()=>any) => void = (func : ()=>any) => bindActionCreators(func, dispatch)();
            return this.dispatchEvents(wrapper)
        };
        return connect(this.mapState, dispatchWrapper)(component);
    }
}