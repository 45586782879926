import React, {CSSProperties} from "react";

interface SizeDropdownProps {

}

interface SizeDropdownState {
    show:boolean
}


export default class SizeDropdown extends React.Component<SizeDropdownProps, SizeDropdownState> {

    constructor(props: SizeDropdownProps) {
        super(props);
        this.state = {show: false}
    }

    render() {
        return (
            <table>
                <tbody>
                    <tr>
                        <td>Iphone</td>
                        <td><input type="radio" aria-label="Iphone" /></td>
                    </tr>
                    <tr>
                        <td>Ipad</td>
                        <td><input type="radio" aria-label="Ipad" /></td>
                    </tr>
                    <tr>
                        <td>Andriod</td>
                        <td><input type="radio" aria-label="Andriod" /></td>
                    </tr>
                    <tr>
                        <td>HD</td>
                        <td><input type="radio" aria-label="HD" /></td>
                    </tr>
                </tbody>
            </table>
        );
    }

    private toggleMenu() {
        this.setState({show: !this.state.show})
    }
}