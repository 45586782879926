export class UUID{
    private readonly value: string;
    
    constructor(value : string) {
        this.value = value;
    }

    static newUUID() : UUID {
        const value =  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
        return new UUID(value);
    }
    
     public equals(compareTo : UUID) : boolean {
        return this.value === compareTo.value;
    }
    
    public toString() : string{
        return this.value;
    }
    
}