import {connect} from 'react-redux';
import * as React from "react";
import {fire, GlobalState} from "../store";
import {
    CanvasActionsType
} from "../store/drawing/reducer";

interface Props {
    groupCount : number
    fireClearDrawingEvent: ()=>void
}

interface State{
}

class DeleteAllButton extends React.Component<Props, State> {

    constructor(props : Props) {
        super(props);
    }

    render(){
        return (
            <button type="button" className="btn btn-secondary"  onClick={this.props.fireClearDrawingEvent}>
                Clear all <span className="badge badge-light">{this.props.groupCount}</span>
            </button>
        );
    }

}

const mapStateToProps = (state:GlobalState)=>{
    const all = state.canvas.groups.getAll();
    let count = 0;
    all.forEach(value => count += value.getShapes().length);
    return ({groupCount: count} as Props)
};
const mapDispatchToProps = {fireClearDrawingEvent: ()=>fire(CanvasActionsType.ClearDrawing)};
export default connect(mapStateToProps, mapDispatchToProps)(DeleteAllButton);
