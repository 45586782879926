import {Coordinate, Line, IShape} from "../store/drawing/state";

export default class CanvasShapeDrawer {
    private readonly canvas: HTMLCanvasElement;
    private lineWidth: number = 2;

    constructor(canvas: HTMLCanvasElement) {
        this.canvas = canvas;
    }

    public paintShapes(shapes: IShape[]) {
        if (shapes.length <= 0)
            return;
        shapes.forEach(s => {
            this.paintShape(s);
        });
    }

    public paintShape(shape: IShape) {
        const line = shape as Line;
        if (line !== undefined) {
            this.paintLine(line.coordinates)
        }
    }

    public paintLine(coordinateList: Coordinate[]) {
        if (coordinateList.length <= 0)
            return;

        const canvas = this.canvas;
        const coordinates = coordinateList.slice(0) as Coordinate[];

        const context = canvas.getContext("2d");
        if (context) {
            context.strokeStyle = 'red';
            context.lineJoin = 'round';
            context.lineWidth = this.lineWidth;

            context.beginPath();

            const start = coordinates.shift() as Coordinate;
            context.moveTo(start.x, start.y);
            coordinates.forEach((c) => {
                context.lineTo(c.x, c.y);
            });

            context.stroke();
        }
    }
    
    public moveTo(x : number, y : number){
        const canvas = this.canvas;
        const context = canvas.getContext("2d");
        if (context) {
            context.setTransform(1, 0, 0, 1, x, y+25);
        }
    }

    public scaleTo(scaleX: number, scaleY: number) {
        const canvas = this.canvas;
        const context = canvas.getContext("2d");
        if (context) {
            context.scale(scaleX, scaleY);
        }
    }
    
    transform(scaleX: number, scaleY: number, moveX: number, moveY: number) {
        const canvas = this.canvas;
        const context = canvas.getContext("2d");
        if (context) {
            context.setTransform(scaleX, 0, 0, scaleY, moveX*scaleX, moveY*scaleY);
        }
    }
    
    public clearCanvas() {
        const canvas = this.canvas;
        const context = canvas.getContext("2d");
        if (context) {
            context.save();
            context.setTransform(1, 0, 0, 1, 0, 0);
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.restore();
        }
    }

    public resizeToParentElement() {
        const htmlCanvasElement = this.canvas;
        htmlCanvasElement.width = htmlCanvasElement.parentElement!.clientWidth;
        htmlCanvasElement.height = htmlCanvasElement.parentElement!.clientHeight;
    }

}