import {UUID} from "../util/UUID";

export interface IActionData {
    id: string;
    name: string;
    groupId: string;
    type:CommandType,
    targets : UUID
}

export enum CommandType {
    click
}

export class ActionEntity {

    public readonly id : UUID;
    public readonly groupId: UUID;
    public readonly name: string;
    public readonly commandType: CommandType;
    public readonly commandTargets: UUID;

    constructor(data: IActionData) {
        this.groupId = new UUID(data.groupId);
        this.name = data.name;
        this.id = new UUID(data.id);
        this.commandType = data.type;
        this.commandTargets = data.targets
    }
}