import * as React from "react";
import {UUID} from "../core/util/UUID";

export interface Data {
    sceneList : UUID[]
}

export interface Actions{
    onSelect : (scene : UUID) => void;
    addNew : () => void;
}

interface State{
}

export class SceneNavigationList extends React.Component<Data & Actions, State> {
    
    constructor(props : Data & Actions) {
        super(props);
    }
    
    render(){
        let list = this.props.sceneList;
        let dropdown = <div/>;
        if(list.length > 10) {
            dropdown = this.generateDropdown(list.slice(10));
            list = list.slice(0, 10);
        }
        
        const buttons = list.map((value, index) => {
                return <button type="button" className="btn btn-secondary" key={index} onClick={event => this.props.onSelect(value)}>
                           {index+1}
                        </button>
            });
        return (
            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                {buttons}
                {dropdown}
                <button type="button" className="btn btn-secondary" onClick={event => this.props.addNew()}>
                    +
                </button>
            </div>
        );
    }
    /*
    * 
    * */
    private generateDropdown(list: UUID[]) {
        const items = list.map((value,index) => {
            return <a className="dropdown-item" href="#" 
                      onClick={event => this.props.onSelect(value)}>
                        {index+11}
                    </a>
        });
        return (
            <div className="btn-group" role="group">
                <button id="btnGroupDrop1" type="button"
                        className="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    Dropdown
                </button>
                <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    {items}
                </div>
            </div>
        );
    }
}
