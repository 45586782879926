import "./PreviewElementList.css"
import * as React from "react";
import {IShape} from "../store/drawing/state";
import {CanvasPreviewComponent} from "./CanvasPreviewComponent"
import {PreviewMenu} from "./PreviewMenu";
import {DnD, IDropDetails} from "../thirdparty_presenters/DnD";
import {UUID} from "../core/util/UUID";

export interface PropEvents{
    deleteShape : (s : PreviewItem) => void;
    groupShapes : (s1 : PreviewItem, s2 : PreviewItem) => void;
    moveShapeNextTo : (movedShape : PreviewItem, shapeBefore : PreviewItem) => void;
    onActionSelected : (selectedGroup : UUID) => void;
}

export interface PropData {
    groups : PreviewItem[];
}

interface State{
    selectedItems:ISelectedItem[];
}

interface ISelectedItem {
    key:number;
}

export interface PreviewItem{
    key:number,
    groupId:UUID,
    shapes:IShape[]
}

export class PreviewElementList extends React.Component<React.PropsWithChildren<PropData & PropEvents>, State> {
    
    constructor(props : PropData & PropEvents) {
        super(props);
        let i = 0;
        this.state = {selectedItems:[]};
    }
    
    render(){
        const items = this.props.groups;
        if(items.length <= 0)
            return (<div>No paintet shapes yet. Please start drawing... :)</div>);
        
        return this.renderListOfShapes(items);
    }

    private renderListOfShapes(items: PreviewItem[]) {
        const elements = items.map(item => {
            const isSelected = !!(this.state.selectedItems.find(s => s.key === item.key));
            return (
            <PreviewMenu key={item.key}
                         handleDeleteEvent={() => this.props.deleteShape(item)}
                         handleShowEvent={() => {this.props.onActionSelected(item.groupId)}}
                         handleSelectEvent={() => {
                             this.selectItem(item.key)
                         }} selected={isSelected}>
                <CanvasPreviewComponent shapes={item.shapes}/>
            </PreviewMenu>
        )});

        return (
            <div className="overflow-auto h-100 d-inline-block previewlist">
                <div className="list-group">
                    <DnD childElements={elements} handleDropEvent={(e) => this.handleDropEvent(e)}/>
                </div>
            </div>
        );
    }

    private selectItem(key: number) {
        const exists = this.state.selectedItems.find(value => value.key === key);
        this.setState({
            selectedItems:(exists) ? [] : [{key:key}]
        });
    }

    private handleDropEvent(e : IDropDetails) {
        if(e.dropDestinationIndex === null)
            return;
        
        const draggedShape = this.props.groups[e.draggedStartIndex];
        const targetShape = this.props.groups[e.dropDestinationIndex];
        if(e.dropOnDraggable)
            this.props.groupShapes(draggedShape, targetShape);
        else
            this.props.moveShapeNextTo(draggedShape, targetShape);
    }
}
