import React, {CSSProperties, PropsWithChildren, RefObject} from "react";

interface ElementDropdownProps {
    title : string;
    onClose? : ()=>void;
}

interface ElementDropdownState {
    show:boolean
}


export default class ElementDropdown extends React.Component<PropsWithChildren<ElementDropdownProps>, ElementDropdownState> {

    private dropdownRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    
    constructor(props: ElementDropdownProps) {
        super(props);
        this.state = {show: false};
    }

    render() {
        
        const style : CSSProperties = {
            display: this.state.show ? "block" : "none"
        };
        
        return (
            <div ref={this.dropdownRef}>
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" onClick={() => this.toggleMenu()}>
                    {this.props.title}
                </button>
                <div className="dropdown-menu dropdown-menu-right"  style={style}  aria-labelledby="dropdownMenuButton">
                    {this.props.children}
                </div>
            </div>
        );
    }

    componentDidMount() {
        document.addEventListener("mousedown", (e : MouseEvent)=>this.handleClickOutside(e.target, this.dropdownRef?.current));
    }
    
    componentWillUnmount() {
        document.removeEventListener("mousedown", (e : MouseEvent)=>this.handleClickOutside(e.target, this.dropdownRef?.current));
    }
    
    private toggleMenu() {
        this.setMenuVisible(!this.state.show);
    }

    private setMenuVisible(show : boolean) {
        this.setState({show: show});
        this.props.onClose?.();
    }

    private handleClickOutside(target: EventTarget | null, elm: HTMLDivElement | null) {
        if(!target || !(target instanceof Node) || !elm)
            return;
        
        const targetNode = target as Node;
        
        if (!elm.contains(targetNode) && window.document.body.className.search("modal-open")<0) {
            this.setMenuVisible(false);
        }
    }
}