import {UUID} from "../util/UUID";
import {ActionEntity, IActionData} from "./actionEntity";
import {GroupEntity} from "./groupEntity";
import {List} from "../util/List";

export class ActionEntityMap {
    
    constructor(actions: IActionData[] = []) {
        actions.forEach(value => this.addOrUpdateAction(new ActionEntity(value)));
    }

    private readonly map = new Map<string, ActionEntity[]>();

    public addOrUpdateAction(action: ActionEntity) {
        if(!this.map.has(action.groupId.toString()))
            this.map.set(action.groupId.toString(), []);
            
        const list = this.map.get(action.groupId.toString()) as ActionEntity[];
        const listObj = new List(list);
        
        const match = listObj.replace(action, (a1,a2) => a1.id.equals(a2.id));
        if(!match) 
            listObj.add(action);
    }

    public getAction(id : UUID) : ActionEntity | null{
        const values : Array<ActionEntity[]> = Array.from(this.map.values());
        for (let value of values) {
            const match = value.find(x => x.id.equals(id));
            if(match)
                return match;
        }
        return null;
    }

    public getActions(group : GroupEntity) : ActionEntity[] | null{
        return this.getActionsFromGroupId(group.id);
    }

    public getActionsFromGroupId(groupId: UUID) : ActionEntity[] | null {
        const result = this.map.get(groupId.toString());
        if (result)
            return result;
        return null;
    }
    
    public getAll() : Array<ActionEntity>{
        const values : ActionEntity[][] = Array.from(this.map.values());
        const list : ActionEntity[] = [];
        values.forEach(v => v.forEach(a => list.push(a)));
        return list;
    }
    
    public exportData() : IActionData[]{
        const arr = this.getAll().map((v) => {return {
            groupId: v.groupId.toString(),
            name: v.name,
            id : v.id.toString(),
            type : v.commandType,
            targets : v.commandTargets
        };});
        return arr;
    }
}