import {ActionEntityMap} from "../../core/entities/actionEntityMap";
import {GroupData} from "../../core/entities/groupEntity";
import {GroupEntityMap} from "../../core/entities/groupEntityMap";
import {UUID} from "../../core/util/UUID";
import {SceneEntityList} from "../../core/entities/sceneEntityList";

export interface DrawingState {
    drawingIsEnabled:boolean;
    selectedScene : UUID
    deprecatedGroups: GroupData[];
    groups: GroupEntityMap;
    actions: ActionEntityMap;
    scenes: SceneEntityList;
}

export interface IShape {
}

export class Shape {
    static equals(s1 : Shape, s2 : IShape) : boolean{
        if(s1 === s2)
            return true;
        if(typeof s1 !== typeof s2)
            return false;
        const l1 = (s1 as Line).coordinates;
        const l2 = (s2 as Line).coordinates;
        if(!l1 || !l2)
            return false;
        if(l1.length != l2.length)
            return false;
        for(let i = 0; i<l1.length; i++){
            if(l1[i].x !== l2[i].x || l1[i].y !== l2[i].y)
                return false;
        }
        return true;
    }
}

export interface Line extends IShape{
    coordinates:Coordinate[];
}

export interface Coordinate {
    x:number,
    y:number
}
