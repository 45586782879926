import {GroupData, GroupEntity} from "./groupEntity";
import {UUID} from "../util/UUID";
import {IShape, Shape} from "../../store/drawing/state";

export class GroupEntityMap {
    
    private shapeMap = new Map<string,GroupEntity>();
    
    constructor(groups: GroupData[] = []) {
        groups.forEach((currentValue) => {
            const uuid = new UUID(currentValue.id);
            const scene = new UUID(currentValue.sceneId);
            if(!this.shapeMap.has(uuid.toString())) 
                this.addGroup(new GroupEntity(uuid,scene));
            
            const entity = this.shapeMap.get(uuid.toString());
            if(entity)
                entity.addShape(currentValue.shape);
        });
    }
    
    public addGroup(group : GroupEntity){
        this.shapeMap.set(group.id.toString(), group);
        group.order = Array.from(this.shapeMap.keys()).length;
    }
    
    public getGroup(selectedGroup: UUID) : GroupEntity | null {
        return this.shapeMap.get(selectedGroup.toString()) || null
    }
    
    public getAllForScene(sceneId : UUID) : Array<GroupEntity>{
        return Array.from(this.shapeMap.values())
            .filter(elm => elm.sceneId.equals(sceneId))
            .sort((x, y) => x.order > y.order ? 1 : -1);
    }

    public getAll() : Array<GroupEntity>{
        return Array.from(this.shapeMap.values())
            .sort((x, y) => x.order > y.order ? 1 : -1);
    }
    
    public getAllShapes(sceneId : UUID) : Array<IShape>{
        const shapes = new Array<IShape>();
        this.getAllForScene(sceneId).forEach(value => value.getShapes().forEach(s => shapes.push(s)));
        return shapes;
    }
    
    public removeGroup(id : UUID){
        this.shapeMap.delete(id.toString());
        this.updateOrderOnAllEntities();
    }

    private updateOrderOnAllEntities() {
        let i = 0;
        this.getAll().forEach(value => value.order = i++);
    }

    public removeGroupBasedOnShape(shape : IShape){
        const group = this.getAll().find(value => value.getShapes().find(s => Shape.equals(s,shape)));
        if(!group)
            throw new Error("The shape does not exist");
        this.removeGroup(group.id);
    }
    
    public removeAll(){
        this.shapeMap.clear();
    }
    
    public exportData() : GroupData[]{
        const data = new Array<GroupData>();
        this.getAll().forEach(value => {
            value.getShapes().forEach(s => data.push({id:value.id.toString(), sceneId:value.sceneId.toString(), shape:s}));
        })
        return data;
    }

    public switchOrder(groupId: UUID, groupId2: UUID) {
        const item1 = this.getGroup(groupId);
        const item2 = this.getGroup(groupId2);
        if(item1 == null || item2 == null)
            return;

        const order = item2.order;
        item1.order = order;
        item2.order = order;
    }

    mergeGroup(groupId: UUID, groupId2: UUID) {
        const item1 = this.getGroup(groupId);
        const item2 = this.getGroup(groupId2);
        if(item1 == null || item2 == null)
            return;
        
        item2.getShapes().forEach(value => item1.addShape(value));
        this.removeGroup(item2.id);
    }
}