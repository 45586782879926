import {ReduxMapper} from "./ReduxMapper";
import {GlobalState} from "../store";
import {EditActionDialogEvents, EditActionDialogProps, GroupViewModel} from "../presenters/EditActionDialog";
import {Action, bindActionCreators, Dispatch} from "redux";
import {ActionEntity, CommandType} from "../core/entities/actionEntity";
import {CreateAddOrUpdateActionEvent} from "../store/drawing/reducer";
import {UUID} from "../core/util/UUID";

export interface DialogProps {
    actionId : UUID | null,
    selectedGroup : UUID
}

export class EditActionDialogContainer extends ReduxMapper<GlobalState, EditActionDialogProps, EditActionDialogEvents, DialogProps> {
    protected dispatchEvents(dispatch: Dispatch<Action<any>>, props: DialogProps): EditActionDialogEvents {
        return {
            onOK: (item) => {
                if (!item.action)
                    throw Error("Illegal save state - missing action");

                const data = {
                    name: item.name,
                    groupId: props.selectedGroup.toString(),
                    id: item.actionId.toString(),
                    type: CommandType.click,
                    targets: item.action?.sceneId
                };
                const entity = new ActionEntity(data);
                bindActionCreators(() => CreateAddOrUpdateActionEvent(entity), dispatch)();
            }
        }
    }

    protected mapState(state: GlobalState, ownProps: DialogProps): EditActionDialogProps {
        const all = state.canvas.scenes.getAll();
        const groups: GroupViewModel[] = all.map<GroupViewModel>(value => {
            return {id: value.id}
        });

        const dialogProps: EditActionDialogProps = {
            item: null,
            groups: groups
        };

        if (ownProps.actionId === null) {
            dialogProps.item = null;
            return dialogProps;
        }

        const action = state.canvas.actions.getAction(ownProps.actionId);
        if (action === null)
            dialogProps.item = {
                actionId: ownProps.actionId,
                name: "",
                action: null,
                event: null
            };
        else {
            dialogProps.item = {
                actionId: action.id,
                name: action.name,
                action: {sceneId: action.commandTargets},
                event: {}
            }
        }
        return dialogProps
    }

}