import {applyMiddleware, combineReducers, createStore, Store} from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import {History} from "history";
import {DrawingState} from "./drawing/state";
import {systemReducer} from "./system/reducer";
import {SystemState} from "./system/state";
import {drawingReducer} from "./drawing/reducer";


export interface GlobalState {
    canvas : DrawingState,
    system: SystemState
}

export interface ActionType<T>{
    type:T
}

export function fire<T>(type:T) : ActionType<T> {
    return {type:type};
}

export default function configureStore(history: History) : Store<GlobalState>{
    
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push((window as any).__REDUX_DEVTOOLS_EXTENSION__());
    }

    const rootReducer = combineReducers({
        canvas: drawingReducer,
        system: systemReducer,
        routing: routerReducer
    });

    return createStore(
        rootReducer,
        applyMiddleware(...middleware)
    );
}


