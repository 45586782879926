import "jquery/"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './index.css';
import React from "react";
import { createRoot } from 'react-dom/client';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'react-router-redux';
import {createBrowserHistory} from 'history';
import configureStore from './store/index';
import LocalStoragePersister from "./store/persistens/localStoragePersister";
import setupPersistens from "./store/persistens/persistStore";
import App from "./App";

// Create browser history to use in the Redux store
const baseUrl = window.location.protocol + '//' + window.location.host + '/';;
const history = createBrowserHistory({ basename: baseUrl});
const store = configureStore(history);

const persister = new LocalStoragePersister();
setupPersistens(store, persister);

const root = createRoot(
    document.getElementById('root') as HTMLElement
  );
  

root.render(
    <React.StrictMode>
        <Provider store={store}>
          <App/>
        </Provider>
    </React.StrictMode>);


//registerServiceWorker();
