import * as React from "react";
import { Component, PropsWithChildren } from "react";
import {Button, Modal} from "react-bootstrap"

interface Props {
    show:boolean;
    title:string;
    onOK:()=>void;
}

interface State{
    show:boolean;
}

export class ModalDialog extends Component<PropsWithChildren<Props>, State> {
    
    private openDialog : boolean = true;
    
    constructor(props : Props) {
        super(props);
        this.handleClose.bind(this);
        this.state = {show:this.props.show};
    }
    
    render(){
        const show = this.openDialog && this.props.show;
        this.openDialog = true;
        return (
            <Modal show={show} onHide={()=>this.handleClose()} >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.children}</Modal.Body>
                <Modal.Footer>
                    <Button className="btn-secondary" onClick={()=>this.handleClose()}>
                        Cancel
                    </Button>
                    <Button className="btn-primary" onClick={()=>{this.props.onOK();this.handleClose();}}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    private handleClose() {
        this.openDialog = false;
        this.forceUpdate();
    }
}
