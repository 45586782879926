import React from 'react';
import {Layout} from './components/Layout';
import {connect} from "react-redux";


interface AppProps {
    
}

class App extends React.Component<AppProps>{
    
    render(){
        return  (
            <Layout />
        )
    }
}

export default connect()(App);