import {ReduxMapperSimple} from "./ReduxMapper";
import {ActionType, GlobalState} from "../store";
import {Actions, Data, SceneNavigationList} from "../presenters/SceneNavigationList";
import {Action, Dispatch} from "redux";
import {AddScene, CreateAddOrUpdateActionEvent, SetScene} from "../store/drawing/reducer";
import {UUID} from "../core/util/UUID";
import {SceneEntity} from "../core/entities/sceneEntity";

class SceneNavigationListMapper extends ReduxMapperSimple<GlobalState, Data, Actions> {
    

    protected mapState(state: GlobalState): Data {
        return {
            sceneList : state.canvas.scenes.getAll().map(value => value.id)
        };
    }

    protected dispatchEvents(dispatch: (func: () => ActionType<any>) => void): Actions {
        return {
            onSelect : scene => {
                dispatch(() => SetScene(scene));
            },
            addNew : () => {
                dispatch(() => AddScene(new SceneEntity(UUID.newUUID())))
            }
        };
    }

}

export const SceneNavigationListContainer = new SceneNavigationListMapper().connect(SceneNavigationList);