import * as React from "react";
import {DragDropContext, Droppable, Draggable, DropResult, ResponderProvided} from "react-beautiful-dnd";


interface Props {
    childElements : any[];
    handleDropEvent : (details : IDropDetails) => void;
}

export interface IDropDetails{
    draggedStartIndex : number;
    dropDestinationIndex: number | null; 
    dropOnDraggable : boolean
}

interface State{
}

export class DnD extends React.Component<Props, State> {
    
    private currentDropTarget : number | null = null;
    
    constructor(props : Props) {
        super(props);
    }
    
    render(){
        const uniqueId = Math.random();
        const children = this.props.childElements.map((child,index) => {
            const id = "draggable_"+uniqueId+"_"+index;
            return (
                <Draggable key={index} draggableId={id} index={index} >
                    {(provided, snapshot) => {
                        let className = new Array<string>();
                        if(snapshot.isDragging)
                            className.push("isDragging");
                        if(snapshot.combineTargetFor) {
                            className.push("isDropTarget");
                            this.currentDropTarget = index;
                        }
                        
                        return (
                        <div className={className.join(" ")}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                        >
                            {child}
                        </div>
                    )}}
                </Draggable>
        )});
        
        return (
            <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                <Droppable droppableId="list" isCombineEnabled={true}>
                    {(provided,snapshot) => (
                        <div className={(snapshot.isDraggingOver)?"active":""} ref={provided.innerRef} {...provided.droppableProps}>
                            {children}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    private onDragEnd(result: DropResult, provided: ResponderProvided) {
        const sourceIndex = result.source.index;
        let moveToIndex = (result.destination) ? result.destination.index : null;
        const isCombined = result.combine !== null;
        if(isCombined)
            moveToIndex = this.currentDropTarget;
        
        this.props.handleDropEvent({draggedStartIndex : sourceIndex, dropDestinationIndex : moveToIndex, dropOnDraggable : isCombined});
    }
}
