import {DrawingState} from "../drawing/state";
import {IStorage} from "./persistStore";

export interface IPersister<T> {
    loadData : ()=>T|undefined
    saveData : (data : T) => boolean
}


export default class LocalStoragePersister implements IPersister<IStorage> {
    
    public loadData():IStorage|undefined{
        try {
            const serializedState = localStorage.getItem('state');
            if (serializedState === null) {
                return undefined;
            }
            
            return JSON.parse(serializedState) ;
        } catch (err) {
            return undefined;
        }
    }

    public saveData(data:IStorage):boolean{
        try {
            const serializedState = JSON.stringify(data);
            localStorage.setItem('state', serializedState);
        } catch {
            return false;
        }
        return true;
    }
}