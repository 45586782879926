import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {fire, GlobalState} from "../store";
import {Coordinate, DrawingState, Line, IShape} from "../store/drawing/state";
import {CanvasActionsType, CreateAddShapeEvent} from "../store/drawing/reducer";
import CanvasShapeDrawer from "../presenters/CanvasShapeDrawer";


interface DrawingCanvasProps {
    shapes: IShape[]
    fireStartDrawingEvent : () => void
    fireStopDrawingEvent : () => void
    fireAddShapeEvent : (shape:IShape) => void
}

interface DrawingCanvasState {
    isDrawing:boolean
    currentShape:IShape | null
}


class CanvasDrawingComponent extends React.Component<DrawingCanvasProps, DrawingCanvasState>{
    
    private canvasRef: RefObject<HTMLCanvasElement> = React.createRef<HTMLCanvasElement>();
    private drawer = () => new CanvasShapeDrawer(this.canvasRef.current!);
    
    
    constructor(props: Readonly<DrawingCanvasProps>) {
        super(props);
        this.state = {isDrawing:false, currentShape:null};
    }

    render(){
        return (
            <div className="canvas">
                <canvas ref={this.canvasRef}
                        onMouseDown={e => this.enableDrawing(e.clientX,e.clientY)} 
                        onMouseUp={e => this.stopDrawing()} 
                        onMouseLeave={e => this.stopDrawing()} 
                        onMouseMove={e=>this.drawMove(e.clientX, e.clientY)}
                        onTouchStart={e => this.enableDrawing(e.touches[0].pageX,e.touches[0].pageY)}
                        onTouchMove={e=>this.drawMove(e.touches[0].pageX,e.touches[0].pageY)}
                        onTouchEnd={e=>this.stopDrawing()}
                        onTouchCancel={e=>this.stopDrawing()}
                        onWheel={e => {this.handleZoom(e); return false;}} 
                        onWheelCapture={e => {this.handleZoom(e); return false;}}/>
                
            </div>
        );
    }
    
    componentDidMount(){
        const canvasShapeDrawer = this.drawer();
        canvasShapeDrawer.resizeToParentElement();
        canvasShapeDrawer.paintShapes(this.props.shapes);
    }
    
    componentDidUpdate(){
        if (this.state.isDrawing)
            return;

        const canvasShapeDrawer = this.drawer();
        this.clearAndPaint(canvasShapeDrawer);
    }

    private clearAndPaint(canvasShapeDrawer: CanvasShapeDrawer) {
        canvasShapeDrawer.clearCanvas();
        canvasShapeDrawer.paintShapes(this.props.shapes);
    }

    private enableDrawing(x:number, y: number){
        const mouseCoordinate = {x:x, y:y};
        const line : Line = {coordinates:[this.convertMouseCoordinateToCanvasCoordinate(mouseCoordinate)]};
        this.setState({isDrawing:true, currentShape:line});
        this.props.fireStartDrawingEvent();
    }
    
    private drawMove(x:number, y:number) {     
        if (!this.state.isDrawing)
            return;
        
        const mouseCoordinate = {x:x, y:y};
        const canvasCoordinate = this.convertMouseCoordinateToCanvasCoordinate(mouseCoordinate);
        const line = this.state.currentShape as Line;
        const coordinateClone = line.coordinates.slice(0);
        coordinateClone.push(canvasCoordinate);
        this.setState({currentShape: {coordinates: coordinateClone} as Line});
        
        const lastCoordinate = line.coordinates[line.coordinates.length-1];
        this.drawer().paintLine([lastCoordinate,canvasCoordinate]);
    }

    private stopDrawing() {
        if (!this.state.isDrawing) 
            return;

        const shape = this.state.currentShape as Line;
        if (shape.coordinates.length > 1) 
            this.props.fireAddShapeEvent(shape);
        this.setState({isDrawing: false, currentShape: null});
        this.props.fireStopDrawingEvent();
    }

    private convertMouseCoordinateToCanvasCoordinate(coor: { x: number; y: number }) : Coordinate{
        if(!this.canvasRef.current)
            throw "Then canvas element is missing, this shouldn't be a possible state";
        const canvas = this.canvasRef.current;
        const boundary = canvas.getBoundingClientRect();
        return {x: coor.x - boundary.left, y: coor.y - boundary.top};
    }

    private handleZoom(e: React.WheelEvent<HTMLCanvasElement>) {
        const scrolldelta = e.deltaY ? e.deltaY/40 : 0;
        console.log(scrolldelta);
        const canvasShapeDrawer = this.drawer();
        canvasShapeDrawer.clearCanvas();
        canvasShapeDrawer.scaleTo(scrolldelta, scrolldelta);
        canvasShapeDrawer.paintShapes(this.props.shapes);
        e.preventDefault();
    }
}

export default connect(
    (state:GlobalState) => ({shapes: state.canvas.groups.getAllShapes(state.canvas.selectedScene)} as DrawingCanvasProps),
    {fireStartDrawingEvent: ()=>fire(CanvasActionsType.StartDrawing),
        fireStopDrawingEvent: ()=>fire(CanvasActionsType.StopDrawing),
        fireAddShapeEvent: (shape:IShape)=>CreateAddShapeEvent(shape)} 
)(CanvasDrawingComponent);
