import {Store} from "redux";
import {IPersister} from "./localStoragePersister";
import {IActionData} from "../../core/entities/actionEntity";
import {GroupData} from "../../core/entities/groupEntity";
import {ActionEntityMap} from "../../core/entities/actionEntityMap";
import {GroupEntityMap} from "../../core/entities/groupEntityMap";
import {GlobalState} from "../index";
import {SceneEntityList} from "../../core/entities/sceneEntityList";

export default function persistStore(store: Store<GlobalState>, persister: IPersister<IStorage>): void {
    const data = persister.loadData();
    if (data !== undefined) {
        const list = new SceneEntityList(data.groups);
        store.getState().canvas = {
            drawingIsEnabled : false,
            selectedScene : list.GetFirst().id,
            deprecatedGroups : data.groups,
            actions : new ActionEntityMap(data.actions),
            groups : new GroupEntityMap(data.groups),
            scenes : list
        };
    }

    store.subscribe(() => {
        const canvas = store.getState().canvas;
        const storage : IStorage = {
            actions : canvas.actions.exportData(),
            groups: canvas.groups.exportData()
        };
        persister.saveData(storage);
    });
}

export interface IStorage {
    actions : IActionData[],
    groups : GroupData[]
}