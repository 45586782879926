import React, {RefObject} from 'react';
import {IShape, Line} from "../store/drawing/state";
import CanvasShapeDrawer from "./CanvasShapeDrawer";


interface CanvasPreviewComponentProps {
    shapes:IShape[]
}


export class CanvasPreviewComponent extends React.Component<CanvasPreviewComponentProps>{
    
    private canvasRef: RefObject<HTMLCanvasElement> = React.createRef<HTMLCanvasElement>();
    private drawer = () => new CanvasShapeDrawer(this.canvasRef.current!);
    
    
    constructor(props: Readonly<CanvasPreviewComponentProps>) {
        super(props);
    }

    render(){
        return (
            <canvas ref={this.canvasRef}/>
        );
    }
    
    componentDidMount(){
        const canvasShapeDrawer = this.drawer();
        this.paintShapesOnCanvas(canvasShapeDrawer);
    }

    private paintShapesOnCanvas(canvasShapeDrawer: CanvasShapeDrawer) {
        if(!this.canvasRef?.current)
            return;
        const box = this.repositioningDrawings(this.props.shapes);
        
        const scaleX = this.canvasRef.current.width/(box.maxX-box.minX);
        const scaleY = this.canvasRef.current.height/(box.maxY-box.minY);
        const scale = Math.floor(Math.min(scaleX, scaleY)*100)/100;
        canvasShapeDrawer.transform(scale, scale,box.minX * -1, box.minY * -1);        
        canvasShapeDrawer.paintShapes(this.props.shapes);
    }

    componentDidUpdate(prevProps: Readonly<CanvasPreviewComponentProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const canvasShapeDrawer = this.drawer();
        canvasShapeDrawer.clearCanvas();
        this.paintShapesOnCanvas(canvasShapeDrawer);
    }

    private repositioningDrawings(shapes:IShape[]) : {minX:number, minY:number, maxX:number, maxY:number}{
        const lines = shapes.map(value => value as Line);
        let box = {minX: lines[0].coordinates[0].x, minY:lines[0].coordinates[0].y, maxX: 0, maxY:0};
        lines.forEach(value => value.coordinates.forEach(c =>  {
            box.minX = Math.min(c.x,box.minX);
            box.minY = Math.min(c.y,box.minY);
            box.maxX = Math.max(c.x, box.maxX);
            box.maxY = Math.max(c.y, box.maxY);
        }));
        return box
    }
        
}
