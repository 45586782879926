import React from 'react';
import ElementDropdown from "../presenters/ElementDropdown";
import SizeDropdown from "../presenters/SizeDropdown";
import "./Layout.css"
import PaintComponent from "./PaintComponent";
import ElementListContainer from "./ElementListContainer";
import DeleteAllButton from "./DeleteAllButton";
import {UUID} from "../core/util/UUID";
import {ActionListContainer} from "./ActionListContainer";
import {SceneNavigationList} from "../presenters/SceneNavigationList";
import {SceneNavigationListContainer} from "./SceneNavigationListContainer";

interface Props {
}

interface State{
    groupIdForActions : UUID | null;
}

export class Layout extends React.Component<Props, State>{

    constructor(props : Props) {
        super(props);
        this.state = { groupIdForActions: null };
    }
    
    render(){
        const containerContent = (!this.state.groupIdForActions) ? 
            (<ElementListContainer onActionSelected={(groupId:UUID)=>this.showActionMenuForSelectedGroup(groupId)} />) : 
            (<ActionListContainer selectedGroup={this.state.groupIdForActions} onBack={()=>this.hideActionMenu()}/>);
        return (
            <div className="layout">
                <div className="container-fluid layout-head">
                    <div className="row">
                        <div className="col-3">
                            <SceneNavigationListContainer/>
                        </div>
                        <div className="col-9">
                            <div className="row justify-content-end">
                                <div className="col-auto">
                                <DeleteAllButton />
                                </div>
                                <div className="col-auto">
                                    <ElementDropdown title={"Dimensions"}><SizeDropdown/></ElementDropdown>
                                </div>
                                <div className="col-auto">
                                    <ElementDropdown title={"Elements"} onClose={()=>this.hideActionMenu()}>
                                        {containerContent}
                                    </ElementDropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid layout-body">
                    <PaintComponent />
                </div>
            </div>
        );
    }

    private showActionMenuForSelectedGroup(groupId: UUID) {
        this.setState({...this.state, groupIdForActions: groupId});
    }

    private hideActionMenu() {
        this.setState({...this.state, groupIdForActions: null});
    }
}




