import {ReduxMapper} from "./ReduxMapper";
import {GlobalState} from "../store";
import {ActionListData, ActionListEvents, IActionItem} from "../presenters/ActionList";
import {Action, Dispatch} from "redux";
import {UUID} from "../core/util/UUID";

interface Props {
    selectedGroup : UUID,
    onActionChange : (id : UUID | null) => void
    onBack: ()=>void;
}

export class ActionListContentContainer extends ReduxMapper<GlobalState, ActionListData, ActionListEvents, Props> {

    protected dispatchEvents(dispatch: Dispatch<Action<any>>, props: Props): ActionListEvents {
        return {
            onCancel: () => {
                props.onBack();
            },
            onEditAction: (item) => {
                props.onActionChange(item.id);
            },
            onNewAction: () => {
                props.onActionChange(UUID.newUUID());
            }
        }
    }

    protected mapState(state: GlobalState, props: Props): ActionListData {
        const actions = state.canvas.actions.getActionsFromGroupId(props.selectedGroup);
        const items: IActionItem[] = [];
        if (actions !== null)
            actions.forEach(value => items.push({name: value.name, id: value.id}));

        const group = state.canvas.groups.getGroup(props.selectedGroup);
        const shapes = group?.getShapes() || [];

        return {
            actions: items,
            selectedShape: shapes
        };
    }
}