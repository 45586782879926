import * as React from "react";
import {Component} from "react";
import {ActionList} from "../presenters/ActionList";
import {UUID} from "../core/util/UUID";
import {EditActionDialog} from "../presenters/EditActionDialog";
import {EditActionDialogContainer} from "./EditActionDialogContainer";
import {ActionListContentContainer} from "./ActionListContentContainer";

const ActionListWrapper = new ActionListContentContainer().connect(ActionList);
const DialogWrapper = new EditActionDialogContainer().connect(EditActionDialog);

interface ActionListContainerProps {
    selectedGroup : UUID,
    onBack : () => void;
}

interface State {
    actionId : UUID | null
}

export class ActionListContainer extends Component<ActionListContainerProps, State>{
    
    constructor(props : ActionListContainerProps) {
        super(props);
        this.state = {
            actionId : null
        }
    }
    
    render() {
        return (<>
            <ActionListWrapper selectedGroup={this.props.selectedGroup} 
                               onActionChange={(id : UUID | null) => this.setState({actionId:id})} 
                               onBack={()=>{this.props.onBack()}}/>
            <DialogWrapper selectedGroup={this.props.selectedGroup} 
                           actionId={this.state.actionId} />
        </>);
    }

}


