import {connect} from 'react-redux';
import * as React from "react";
import {GlobalState} from "../store";
import {PreviewElementList, PreviewItem} from "../presenters/PreviewElementList";
import {CreateDeleteShapeEvent, CreateGroupShapeEvent, CreateMoveShapeEvent} from "../store/drawing/reducer";
import {IShape, Shape} from "../store/drawing/state";


const mapStateToProps = (state:GlobalState) => {
    const viewmodel : PreviewItem[] = [];
    
    state.canvas.groups.getAllForScene(state.canvas.selectedScene).forEach(value => {
        viewmodel.push({shapes:value.getShapes(), key:viewmodel.length, groupId: value.id});
    });
    
    return { 
            groups : viewmodel,
        };
    };
const mapDispatchToProps = {deleteShape : (shape:PreviewItem) => CreateDeleteShapeEvent(shape.groupId), 
    groupShapes : (s1 : PreviewItem, s2 : PreviewItem) => CreateGroupShapeEvent(s1.groupId,s2.groupId),
    moveShapeNextTo : (movedShape : PreviewItem, shapeBefore : PreviewItem) => CreateMoveShapeEvent(movedShape.groupId, shapeBefore.groupId)};
const ElementListContainer = connect(mapStateToProps, mapDispatchToProps)(PreviewElementList);
export default ElementListContainer;
