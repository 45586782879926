import CanvasDrawingComponent from "./CanvasDrawingComponent";
import "./PaintComponent.css";
import * as React from "react";

export default class PaintComponent extends React.Component {

    render(){
        return (
            <div className="drawarea container-fluid">
                <CanvasDrawingComponent/>
            </div>
        );   
    }
    
}


