import {ActionType} from "../index";
import {DrawingState, IShape} from "./state";
import {ActionEntityMap} from "../../core/entities/actionEntityMap";
import {GroupEntityMap} from "../../core/entities/groupEntityMap";
import {UUID} from "../../core/util/UUID";
import {ActionEntity} from "../../core/entities/actionEntity";
import {GroupEntity} from "../../core/entities/groupEntity";
import {SceneEntityList} from "../../core/entities/sceneEntityList";
import {SceneEntity} from "../../core/entities/sceneEntity";


export enum CanvasActionsType{
    AddScene,
    SetScene,
    AddAction,
    MoveShape,
    GroupShapes,
    DeleteShape,
    StartDrawing,
    StopDrawing,
    ClearDrawing,
    ShapeAdded
}


export function CreateAddShapeEvent(shape:IShape) : ItemAction<IShape>{
    return {
        type: CanvasActionsType.ShapeAdded,
        item: shape
    }
}

export function CreateDeleteShapeEvent(shape:UUID) : ItemAction<UUID>{
    return {
        type: CanvasActionsType.DeleteShape,
        item: shape
    }
}

export function CreateGroupShapeEvent(s1:UUID, s2:UUID) : TwoItemAction<UUID> {
    return {
        type: CanvasActionsType.GroupShapes,
        item1 : s1,
        item2 : s2
    }
}

export function CreateMoveShapeEvent(s1:UUID, s2:UUID) : TwoItemAction<UUID> {
    return {
        type: CanvasActionsType.MoveShape,
        item1 : s1,
        item2 : s2
    }
}

export function  CreateAddOrUpdateActionEvent(a : ActionEntity) : ItemAction<ActionEntity>{
    return {
        type: CanvasActionsType.AddAction,
        item : a
    }
}

export function  AddScene(scene : SceneEntity) : ItemAction<SceneEntity>{
    return {
        type: CanvasActionsType.AddScene,
        item : scene
    }
}

export function  SetScene(sceneId : UUID) : ItemAction<UUID>{
    return {
        type: CanvasActionsType.SetScene,
        item : sceneId
    }
}

interface ItemAction<T> extends ActionType<CanvasActionsType> {
    item: T
}

interface TwoItemAction<T> extends ActionType<CanvasActionsType> {
    item1: T;
    item2 : T;
}

const initialState:DrawingState = { 
    drawingIsEnabled: false,
    selectedScene: UUID.newUUID(),
    deprecatedGroups: [],
    actions: new ActionEntityMap(),
    groups: new GroupEntityMap(),
    scenes: new SceneEntityList()
};


export function drawingReducer(state = initialState, action: ActionType<CanvasActionsType>): DrawingState {

    const groupEntityMap = state.groups;
    const sceneEntityList = state.scenes;
    switch (action.type) {
        case CanvasActionsType.AddScene:
            const newScene = (action as ItemAction<SceneEntity>).item;
            sceneEntityList.add(newScene);
            return {...state, scenes:sceneEntityList, selectedScene: newScene.id};
        case CanvasActionsType.SetScene:
            const scene = (action as ItemAction<UUID>).item;
            return {...state, selectedScene: scene};
        case CanvasActionsType.ShapeAdded:
            const shape = (action as ItemAction<IShape>).item;
            groupEntityMap.addGroup(GroupEntity.createNewWithShape(state.selectedScene, shape));
            return {...state, groups: groupEntityMap};
        case CanvasActionsType.DeleteShape:
            groupEntityMap.removeGroup((action as ItemAction<UUID>).item);            
            return {...state, groups: groupEntityMap};
        case CanvasActionsType.MoveShape:
            const moveAction = action as TwoItemAction<UUID>;
            groupEntityMap.switchOrder(moveAction.item1, moveAction.item2);
            return {...state, groups: groupEntityMap};
        case CanvasActionsType.GroupShapes:
            const groupAction = action as TwoItemAction<UUID>;
            groupEntityMap.mergeGroup(groupAction.item1, groupAction.item2);
            return {...state, groups:groupEntityMap};
        case CanvasActionsType.AddAction:
            const a = action as ItemAction<ActionEntity>;
            const actionEntityMap = state.actions;
            actionEntityMap.addOrUpdateAction(a.item);
            return {...state, actions: actionEntityMap};
        case CanvasActionsType.StartDrawing:
            return { ...state, drawingIsEnabled: true};
        case CanvasActionsType.StopDrawing:
            return {...state, drawingIsEnabled: false};
        case CanvasActionsType.ClearDrawing:
            groupEntityMap.removeAll();
            sceneEntityList.removeAll();
            return {...state, groups: groupEntityMap, scenes: sceneEntityList, selectedScene: sceneEntityList.GetFirst().id};
        default:
            return state;
    }
}

