import * as React from "react";
import {CanvasPreviewComponent} from "./CanvasPreviewComponent";
import {IShape} from "../store/drawing/state";
import {UUID} from "../core/util/UUID";


export interface ActionListData {
    selectedShape : IShape[];
    actions : IActionItem[];
}

export interface ActionListEvents {
    onCancel : () => void;
    onEditAction : (item : IActionItem) => void;
    onNewAction : () => void;
}

interface State{
    selectedItem : IActionItem | null
}

export interface IActionItem {
    id: UUID;
    name: string;
}

interface Props extends ActionListData, ActionListEvents{}

export class ActionList extends React.Component<Props, State> {
    
    constructor(props : Props) {
        super(props);
        this.state = {selectedItem: null};
    }
    
    render(){
        const items = this.props.actions.map((value, index) => {
            return (<a className="dropdown-item" href="#" key={index}  onClick={() => this.props.onEditAction(value)}>{value.name}</a>)
        });
        const emptyText = (items.length <= 0) ? "No actions added to shape" : "";       
        return (
            <div>
                <div><a className="btn btn-outline-primary" onClick={() => this.props.onCancel()}>Back</a></div>
                <div><CanvasPreviewComponent shapes={this.props.selectedShape}/></div>
                <div className="overflow-auto h-100 d-inline-block">
                    <div className="list-group">
                        <h6 className="dropdown-header">Actions</h6>
                        {emptyText}
                        {items}
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item" onClick={() => this.props.onNewAction()}>
                            <svg className="bi bi-plus-square" width="16px" height="16px" viewBox="0 0 16 16"
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z"
                                      clipRule="evenodd"/>
                                <path fillRule="evenodd"
                                      d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z"
                                      clipRule="evenodd"/>
                                <path fillRule="evenodd"
                                      d="M14 1H2a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V2a1 1 0 00-1-1zM2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2z"
                                      clipRule="evenodd"/>
                            </svg>
                            &nbsp;
                            Add Action
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
